.App {
  text-align: center;
}

.form-group {
  border: 0.1em solid;
  background-color: whitesmoke;
   margin-bottom: 5em;
   padding: 1em;
  border-radius: 30px;
}

.block-element {
  padding: 1em;
  }


table {
  border-spacing: 5em 1em;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

main {
	margin: 2em;
}

